import {useMemo} from 'react';
import {paths} from 'src/routes/paths';
import {useLocales} from 'src/locales';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import {Role} from "../../auth/guard/role";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

function getNavData(t: any) {
  const sections = [
    {
      subheader: t('Admin'),
      items: [
        {
          title: t('Gateways'),
          path: paths.dashboard.gateways.root,
          roles: [Role.ADMIN],
          icon: <Iconify icon="ic:baseline-paypal" />,
        },
        {
          title: t('Fulfills'),
          path: paths.dashboard.fulfills.root,
          roles: [Role.ADMIN],
          icon: (
            <Iconify icon="streamline:shipping-transfer-cart-package-box-fulfillment-cart-warehouse-shipping-delivery" />
          ),
        },
        {
          title: t('Products'),
          path: paths.dashboard.products.root,
          roles: [Role.SHOP_MANAGER],
          icon: ICONS.product,
        },
        {
          title: t('Seller Fees'),
          path: paths.dashboard.sellerFees.root,
          roles: [Role.ADMIN],
          icon: <Iconify icon="ic:baseline-money-off" />,
        },
        {
          title: t('Seller Products'),
          path: paths.dashboard.sellerProducts.root,
          roles: [Role.SHOP_MANAGER],
          icon: <Iconify icon="eos-icons:products-outlined" />,
        },
        {
          title: t('Stores'),
          path: paths.dashboard.stores.root,
          roles: [Role.SHOP_MANAGER],
          icon: <Iconify icon="material-symbols:store" />,
        },
        {
          title: t('Store Products'),
          path: paths.dashboard.storeProducts.root,
          roles: [Role.SHOP_MANAGER],
          icon: <Iconify icon="fluent-mdl2:product-variant" />,
        },
        {
          title: t('Users'),
          path: paths.dashboard.users.root,
          roles: [Role.ADMIN],
          icon: <Iconify icon="mdi:users" />,
        },
        {
          title: t('Payouts'),
          path: paths.dashboard.payouts.root,
          roles: [Role.ADMIN],
          icon: <Iconify icon="uil:money-withdraw" />,
        },
        {
          title: t('Pixels'),
          path: paths.dashboard.pixels.root,
          roles: [Role.ADMIN],
          icon: <Iconify icon="mdi:code" />,
        },
        {
          title: t('Background Tasks'),
          path: paths.dashboard.backgroundTasks.root,
          roles: [Role.ADMIN],
          icon: <Iconify icon="mdi:worker" />,
        },
        {
          title: t('User Activities'),
          path: paths.dashboard.userActivities.root,
          roles: [Role.ADMIN],
          icon: <Iconify icon="tabler:activity" />,
        },
      ],
    },
    {
      subheader: t('Paypal'),
      items: [
        {
          title: t('Disputes'),
          path: paths.dashboard.disputes.root,
          roles: [Role.ADMIN],
          icon: <Iconify icon="mdi:sword-fight" />,
        },
      ],
    },
    {
      subheader: t('DropShipping Products'),
      items: [
        {
          title: t('Products'),
          path: paths.dashboard.dspProducts.root,
          roles: [Role.SELLER, Role.SHOP_MANAGER],
          icon: ICONS.product,
        },
        {
          title: t('Request'),
          path: paths.dashboard.quotation.root,
          roles: [Role.SELLER, Role.SHOP_MANAGER],
          icon: <Iconify icon="material-symbols:request-page-outline" />,
        },
      ],
    },
    {
      subheader: t('Shop'),
      items: [
        {
          title: t('Orders'),
          path: paths.dashboard.orders.root,
          roles: [Role.SELLER, Role.SHOP_MANAGER],
          icon: <Iconify icon="mdi:cart" />,
        },
      ],
    },
    {
      subheader: t('Finance'),
      items: [
        {
          title: t('Wallet'),
          path: paths.dashboard.wallet.root,
          roles: [Role.SELLER],
          icon: <Iconify icon="ion:wallet-outline" />,
        },
      ],
    },
    {
      subheader: t('Analytics'),
      items: [
        {
          title: t('Revenue'),
          path: paths.dashboard.revenue.root,
          roles: [Role.SELLER, Role.SHOP_MANAGER],
          icon: <Iconify icon="mdi:report-box" />,
        },
        {
          title: t('Refund'),
          path: paths.dashboard.refund.root,
          roles: [Role.SELLER, Role.SHOP_MANAGER],
          icon: <Iconify icon="gridicons:refund" />,
        },
        {
          title: t('Online Users'),
          path: paths.dashboard.onlineUsers.root,
          roles: [Role.SELLER, Role.SHOP_MANAGER],
          icon: <Iconify icon="mdi:account-online" />,
        },
        {
          title: t('Traffic'),
          path: paths.dashboard.traffic.root,
          roles: [Role.SELLER, Role.SHOP_MANAGER],
          icon: <Iconify icon="mynaui:click" />,
        },
      ],
    },
    {
      subheader: t('Ad Library'),
      items: [
        {
          title: t('Tasks'),
          path: paths.dashboard.adLibraryTasks.root,
          roles: [Role.SPY],
          icon: <Iconify icon="material-symbols:search" />,
        },
        {
          title: t('Ads'),
          path: `${paths.dashboard.adLibraryAds.root}?flag=0`,
          roles: [Role.SPY],
          icon: <Iconify icon="material-symbols:ad" />,
        },
      ],
    },
    {
      subheader: t('Tool'),
      items: [
        {
          title: t('Share Pixel'),
          path: paths.dashboard.sharePixel.root,
          roles: [Role.SELLER],
          icon: <Iconify icon="material-symbols:share" />,
        },
        {
          title: t('BIN Checker'),
          path: paths.dashboard.binChecker.root,
          roles: [Role.ADMIN],
          icon: <Iconify icon="mdi:bank" />,
        },
        {
          title: t('Notifications'),
          path: paths.dashboard.user.account.notifiations,
          roles: [Role.SELLER, Role.SHOP_MANAGER],
          icon: <Iconify icon="ic:baseline-telegram" />,
        },
      ],
    },
  ];

  for (let i = 0; i < sections.length; i++) {
    const section: any = sections[i];
    section.roles = [];

    for (let j = 0; j < section.items.length; j++) {
      const item = section.items[j];
      if (item.roles) {
        item.roles.forEach((role: string) => {
          if (!section.roles.includes(role)) {
            section.roles.push(role);
          }
        });
      }
    }
  }

  return sections;
}

export function useNavData() {
  const { t } = useLocales();
  return useMemo(() => getNavData(t),[t]);
}
